import(/* webpackMode: "eager", webpackExports: ["Root","Item","Header","Trigger","Content"] */ "/vercel/path0/node_modules/@radix-ui/react-accordion/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/door1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/door2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/door3.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/hero.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/img1.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/img2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/img3.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/phone-customer.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/phone-rider.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/images/phone-vendor.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/AppRole/AppRole.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/DoorStep/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/FAQs/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Hero/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/OneStop/OneStop.tsx");
