"use client";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import { FC } from "react";
import oneStopImg from "../../../public/assets/images/oneStopImg.png";
import AppleStore from "../../../public/assets/svg/AppleStore";
import PlayStore from "../../../public/assets/svg/PlayStore";
import { oneStopAds } from "../../data/constant";
import styles from "./styles.module.scss";

interface OneStopProps {}

const animation = { duration: 20000, easing: (t: number) => t };

const OneStop: FC<OneStopProps> = (): JSX.Element => {
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    renderMode: "performance",
    drag: false,
    // rtl: false,
    slides: { perView: "auto", spacing: 30 },
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
  });

  return (
    <div id="mainContainer" className={styles.oneStopContainer}>
      <div className={styles.oneStop}>
        <div className={styles.left}>
          <h4>
            Your One-Stop <br /> Delivery App!
          </h4>

          <div className={styles.cta}>
            <a
              href="http://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <PlayStore />

              <p>
                <span>Available soon on</span>
                <br />
                <span className={styles.available}>Google Play</span>
              </p>
            </a>

            <a
              href="http://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppleStore />

              <p>
                <span>Available soon on</span>
                <br />
                <span className={styles.available}>App Store</span>
              </p>
            </a>
          </div>
        </div>

        <div className={styles.right}>
          <Image alt="hero background" src={oneStopImg} />
        </div>
      </div>

      <div ref={sliderRef} className="keen-slider">
        {oneStopAds.map((text, index) => (
          <p
            className={`${styles.slides} keen-slider__slide`}
            key={index.toFixed()}
          >
            {text}
          </p>
        ))}
      </div>
    </div>
  );
};

export default OneStop;
