"use client";

import Image, { StaticImageData } from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import { FC, Suspense, useEffect, useState } from "react";
import AppleStore from "../../../public/assets/svg/AppleStore";
import PlayStore from "../../../public/assets/svg/PlayStore";
import { AppRoleContentsDate } from "../../data/constant";
import { slugify } from "../../utils";
import styles from "./styles.module.scss";

interface AppRoleProps {}
interface AppRoleContentsProps {
  model: {
    model: string;
    bannerImg: StaticImageData;
    bannerTitle: string;
    appLink: {
      apple: string;
      google: string;
    };
    phoneImg: StaticImageData;
    contents: {
      title: string;
      subTitle: string;
      icon: () => JSX.Element;
    }[];
  };
}

const roleNav = ["Customer", "Vendor", "Rider"];

const AppRoleContents: FC<AppRoleContentsProps> = ({ model }) => {
  return (
    <div className={styles.appRoleContents}>
      <div className={styles.left}>
        <Image
          alt="overlay background"
          src={model.bannerImg}
          className={styles.bannerImg}
        />

        <div className={styles.overlay} />

        <div className={styles.bannerContent}>
          <h4>{model.bannerTitle}</h4>

          <div className={styles.cta}>
            <a
              href={model.appLink.apple}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="App store"
            >
              <AppleStore />
            </a>

            <a
              href={model.appLink.google}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Google store"
            >
              <PlayStore />
            </a>
          </div>
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.model}>for {model.model}</div>

        <div className={styles.modelContents}>
          <div>
            {model.contents.map((data, i) => (
              <div className={styles.content} key={i.toFixed()}>
                <div className={styles.leftContent}>
                  <div className={styles.icon}>{data.icon()}</div>

                  <span />
                </div>

                <div className={styles.rightContent}>
                  <p>{data.title}</p>

                  <p>{data.subTitle}</p>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.phone}>
            <Image alt="phone" src={model.phoneImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

const AppRoleComponent: FC<AppRoleProps> = (): JSX.Element => {
  const [selectedModel, setSelectedModel] = useState<
    "customer" | "vendor" | "rider"
  >("customer");
  const router = useRouter();
  const searchParams = useSearchParams();
  const currentRole = searchParams.get("role") || "customer";

  const handleRoleChange = (nav: string) => {
    const newRole = slugify(nav);
    const newUrl = `${window.location.pathname}?role=${newRole}`;

    router.push(newUrl, { scroll: false });
  };

  useEffect(() => {
    setSelectedModel(currentRole as "customer" | "vendor" | "rider");
  }, [currentRole]);

  return (
    <div className={styles.roles} id="mainContainer">
      <h3 id="appRole">
        <span className={styles.top}>Everyone Has a Role</span>

        <span className={styles.bottom}>
          Join us as a Customer, Rider, or Vendor—together, <br /> we make it
          happen!
        </span>
      </h3>

      <div className={styles.nav}>
        {roleNav.map((nav, index) => {
          const isActive = currentRole === slugify(nav);
          return (
            <button
              type="button"
              onClick={() => handleRoleChange(nav)}
              key={index.toFixed()}
              className={isActive ? styles.active : ""}
            >
              {nav}
            </button>
          );
        })}
      </div>

      <AppRoleContents model={AppRoleContentsDate[selectedModel]} />
    </div>
  );
};

const AppRole: FC<AppRoleProps> = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AppRoleComponent />
    </Suspense>
  );
};

export default AppRole;
